<template>
<v-container fluid class="pa-0 header-parent d-none d-lg-flex">
  <v-row class="justify-end header-bar mx-0 align-center">
      <v-col cols="auto">
        <div class="text-center menu">
          <v-menu offset-y class>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="transparent" dark elevation="0" v-bind="attrs" v-on="on">
                <p class="copperplate white-black body-2 mb-0 " v-if="getUser">{{ $t("menu.myAccount") }}</p>
                 <p class="copperplate white-black body-2 mb-0 " v-if="!getUser">{{ $t("menu.logIn") }}</p>
              </v-btn>
            </template>
            <v-list class="pt-5" v-if="getUser">
              <v-list-item to="/profile">
                <v-list-item-title class="p josefin-light text-center mb-0">
                  {{ $t("menu.myAccount") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="d-flex justify-center">
                <v-btn class="transparent elevation-0" @click="logoutUserBtn()">
                  <span class="josefin-light p text-capitalize black-grin">
                    {{ $t("menu.logOut") }}</span
                  >
                </v-btn>
              </v-list-item>
            </v-list>
            <v-list class="pt-5 px-5" v-else>
              <v-list-item v-for="(item, index) in menu_first" :key="index" :to="item.to">
                <v-list-item-title class="p josefin-light text-center mb-0">{{
                  $t(item.title)
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>

      <v-col cols="auto">
        <div class="text-center menu">
          <v-menu offset-y class="resume-cart" nudge-top="18" dark >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="transparent" dark elevation="0" v-bind="attrs" v-on="on">
                <p class="copperplate white-black body-2 mb-0 ">{{ $t("menu.cart") }}</p>
                <div class="red text-center" v-show="cartShow">
                  {{ productsCart.items_count }}
                </div>
              </v-btn>
            </template>
            <v-col class="resume-cart px-7 pt-12" v-if="productsCart">
              <v-row class="mb-n4 mt-1">
                <p
                  class="copperplate"
                  v-html="$t('cart.subtitle', { count: productsCart.items_count })"
                ></p>
              </v-row>
              <v-row class="row-gold mb-n7"
              v-for="(item, index) in productsCart.items.slice(0,2)" :key="index">
                <v-col cols="3" class="d-flex align-center">
                  <v-img contain :src="item.product.base_image.small_image_url" width="95"></v-img>
                </v-col>
                <v-col cols="8">
                  <p class="copperplate font-12 text-uppercase"
                  v-html="item.product.short_description"> </p>
                    <div >
                      <v-row class="mr-8">
                      <v-col cols="8">
                        <p class="josefin-light  font-12"
                        v-html="item.product.name + ' (' + item.quantity + ')'"></p
                      ></v-col>
                      <v-col cols="4">
                        <p class="copperplate gold  font-12"
                        v-html="'RD$'+Intl.NumberFormat().format(item.price) + '.00'"></p
                      ></v-col>
                      </v-row>
                    </div>
                </v-col>
              </v-row>
              <v-row class="row-gold">
                <v-col cols="6">
                   <p class="copperplate font-12">Sub-total</p>
                </v-col>
                <v-col cols="6">
                  <p class="copperplate text-right font-12"
                  v-html="'RD$'+Intl.NumberFormat().format(productsCart.base_sub_total)+'.00'">
                  </p></v-col>
              </v-row>
              <v-row justify="center" class="mb-1 mt-n4">
                 <router-link to="/cart" class="payment-button">
              <v-btn class="btn_second px-7"
              elevation="0" medium>{{$t('cart.btnGoToCart')}}</v-btn>
            </router-link>
              </v-row>
            </v-col>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="auto">
      <v-select
        dark
        :items="languages.data"
        outlined
        class="page-language"
        v-model="lang"
        item-text="code"
        @input="changeLanguage(lang)"
      ></v-select>
      </v-col>
  </v-row>
</v-container>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";
import router from "../../router";

export default {
  data() {
    return {
      menu_first: [
        {
          title: "menu.logIn",
          to: "/log-in"
        },
        {
          title: "menu.register",
          to: "/register"
        }
      ],
      menu_second: [
        {
          title: "Mi Cuenta",
          to: "/profile"
        },
        {
          title: "Cerrar Sesión",
          to: "/log-in"
        }
      ],
      productsCart: "",
      lang: localStorage.getItem("lang") || "es",
      languages: [],
      cartShow: false,
    };
  },
  computed: {
    ...mapGetters("customer", ["getUser"]),
    ...mapGetters(["getLocale"]),
    ...mapGetters("shoppingCart", ["getCart"])
  },
  methods: {
    ...mapActions("customer", ["logoutUser"]),
    ...mapActions("shoppingCart", ["fetchCartDetails"]),
    ...mapActions(["fetchGetLocale"]),
    async logoutUserBtn() {
      try {
        await this.logoutUser();
        
      } catch (error) {
       
      }
    },
    changeLanguage(lang) {
      localStorage.setItem("lang", lang);
      this.$router.go();
    }
  },
  async created() {
    this.fetchGetLocale();
    if (this.getUser) {
     await  this.fetchCartDetails();
    }
  },
  watch: {
    getLocale(val) {
      this.languages = val;
    },
    getCart(val) {
      if (val) {
        this.productsCart = val;
        this.cartShow = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.v-menu__content.theme--light.menuable__content__active {
  border-radius: 0 !important;
  margin-left: -72px;
  min-width: 180px !important;
  max-width: 350px;
  clip-path: polygon(60% 10%, 73% 4%, 85% 10%, 100% 10%, 100% 100%, 0 100%, 0 10%) !important;
}
.resume-cart[data-v-5f8e0eba]{
clip-path: polygon(73% 10%, 80% 7%, 87% 10%, 100% 10%, 100% 100%, 0 100%, 0 10%) !important;
}
.resume-cart {
  clip-path: polygon(60% 10%, 73% 4%, 85% 10%, 100% 10%, 100% 100%, 0 100%, 0 10%);
  background: white !important;
  min-width: 200px !important;
}
.row-gold {
  border-top: 1px solid rgba(229, 201, 127, 1);
}
.btn_second {
  height: 30px !important;
  color: rgba(0,0,0,0.8)
}
.v-menu__content {
  max-width: 350px !important;
  margin-left: -82px !important;
}
</style>
