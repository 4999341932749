<template>
  <v-container fluid class="pa-0">
    <header-bar style="max-width: 80%"></header-bar>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="banner d-flex align-end px-lg-16">
          <v-card-text class="pt-16 px-lg-16 ">
            <p class="copperplate h1 white-black text-center text-lg-left"
            v-html="$t(title)">
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" no-gutters>
        <v-toolbar color="rgba(229, 201, 127, 1)" height="20px"> </v-toolbar>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import headerBar from "@/components/home/header-bar.vue";

export default {
  components: {
    headerBar,
  },
  props: ["title"]
};
</script>
