<template>
  <v-container fluid class="pa-0">
    <banner :title="$t('programs.programsRonDonMiguel')"></banner>
    <v-container fluid class="pa-lg-16 pa-10 min-height">
      <v-row class="my-0 mx-lg-12">
        <v-col cols="12" lg="6" v-for="(item, index) in getPrograms" :key="index">
          <v-img :src="item.image">
            <v-container class="pa-10">
              <v-row>
                <h2 class="h1 copperplate ma-0 white-black" v-html="item.name"></h2>
              </v-row>
              <v-row>
                <v-btn
                  class="btn_first align-star px-10 mt-10 mb-16"
                  :ripple="{ class: 'white--text' }"
                  elevation="0"
                  medium
                  :to="item.url"
                  >{{$t('programs.btnGetToKnowHim')}}</v-btn
                >
              </v-row>
            </v-container>
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import banner from "@/components/home/banner.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    banner
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("programs", ["getPrograms"])
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
 .btn_first {
   color: white !important;
 }
  .btn_first:hover {
   color: black !important;
 }
</style>
